<template>

  <div class="card z-index-2">
      <div class="card-body">

        <hr class="dark horizontal" />
        <div class="d-flex justify-content-center">
             <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active register-accordion" id="unregistered-tab" data-bs-toggle="tab" data-bs-target="#unregistered" type="button" role="tab" aria-controls="home" aria-selected="true">New to Crownscope?</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link register-accordion" id="registered-tab" data-bs-toggle="tab" data-bs-target="#registered" type="button" role="tab" aria-controls="profile" aria-selected="false">Already Registered?</button>
                </li>
            </ul>
         </div>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="unregistered" role="tabpanel" aria-labelledby="home-tab">
            <form role="form" class="text-start mt-3 unregistered-form">
              <div class="mb-3">
                <material-input
                    id="firstname"
                    type="text"
                    label="First name"
                    name="first_name"
                    v-model:value="first_name"
                    :error="isFieldInvalid('first_name')"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="middlename"
                    type="text"
                    label="Middle name (optional)"
                    name="middle_name"
                    v-model:value="middle_name"
                    :error="isFieldInvalid('middle_name')"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="lastname"
                    type="text"
                    label="Last name"
                    name="last_name"
                    v-model:value="last_name"
                    :error="isFieldInvalid('last_name')"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="email"
                    type="email"
                    label="Email Address"
                    name="email_address"
                    v-model:value="email_address"
                    :error="isFieldInvalid('email_address')"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="phone_number"
                    type="number"
                    label="Phone Number"
                    name="phone_number"
                    v-model:value="phone_number"
                    :error="isFieldInvalid('phone_number')"
                />
              </div>
              <div class="mb-3">
                <datepicker  placeholder="Select Date of Birth"
                             v-model="dob"
                             ></datepicker>
              </div>
              <div class="mb-3">
                <material-input
                    id="business_occupation"
                    type="text"
                    label="Business Occupation"
                    name="business_occupation"
                    v-model:value="business_occupation"
                    :error="isFieldInvalid('business_occupation')"
                />
              </div>
              <div class="mb-3">
                <div class="input-group input-group-outline null">
                  <select
                      id="nationality"
                      class="input-group input-group-outline form-control"
                      v-model="selectedNationality"
                      name="nationality"
                  >
                    <option value="" disabled selected>Select Location</option>
                    <option value="kenya">Kenya</option>
                    <option value="outside-kenya">Outside Kenya</option>
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <div class="input-group input-group-outline">
                  <label for="smoker" class="smoke-label">Do you smoke?</label>
                  <material-switch
                      id="yes_smoke"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault"
                      label-class="mb-0 text-body text-truncate w-60"
                      :error="isSmokerInvalid && isFormSubmitted"
                      @input="validateSmoker('yes')"
                      :checked="yesSmokeSelected"
                  >Yes</material-switch>
                  <material-switch
                      id="no_smoke"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault"
                      label-class="mb-0 text-body text-truncate w-60"
                      :error="isSmokerInvalid && isFormSubmitted"
                      @input="validateSmoker('no')"
                      :checked="noSmokeSelected"
                  >No</material-switch>
                  <!-- Display an error message for the switches -->
                  <div v-if="isSmokerInvalid && isFormSubmitted" class="text-danger">Please select a smoking status</div>
                </div>
              </div>
              <div class="mb-3">
                <material-input
                    id="password"
                    type="password"
                    label="Password"
                    name="password"
                    v-model:value="password"
                    :error="isFieldInvalid('password')"
                />
              </div>
              <div v-if="passwordNotMatch" class="text-danger">Passwords do not match</div>
              <div class="mb-3">
                <material-input
                    id="confirm_password"
                    type="password"
                    label="Confirm Password"
                    name="confirm_password"
                    v-model:value="confirm_password"
                    :error="isFieldInvalid('confirm_password')"

                />
              </div>
<!--              <p class="error" v-if="formData.passwordError">{{ formData.passwordError }}</p>-->

              <div class="text-center">
                <material-button
                    class="my-4 mb-2 checkout-login crown-scope-button"
                    variant="gradient"
                    color="success"
                    @click.prevent="submitSignupForm()"
                    fullWidth><i v-if="showLoader" class="fa fa-spinner fa-spin"></i>Continue to checkout</material-button>
              </div>
              <p class="checkout-option-or">OR</p>
              <p  class="mt-4 text-sm text-center signup-call">
                SIGN UP WITH...
              </p>
              <div class="row mt-3 text-center">
                <div class="text-center me-auto">

                  <material-button
                      class="my-4 mb-2 checkout-login crown-scope-button google-button"
                      variant="gradient"
                      color="success"
                      @click.prevent="signupGoogle()"
                      fullWidth>
                    <img
                        src="@/assets/img/illustrations/google-logo.png"
                        alt="profile_image"
                        class="shadow-sm w-10 border-radius-lg"
                    /><span class="google-text">Google</span></material-button>

                </div>
              </div>
              <div class="row mt-3 text-center">
                <p class="additional-information">* We are making it easy for you to get insured that is why we are only asking for minimum required information for you to
                  take out cover.Please fill the information above so you only have to do this once.
                </p>
              </div>
            </form>
          </div>
          <div class="tab-pane fade" id="registered" role="tabpanel" aria-labelledby="profile-tab">
            <div class="text-center">
              <material-button
                  @click.prevent="expressCheckout()"
                  class="my-4 mb-2 checkout-login crown-scope-button"
                  fullWidth>Continue to checkout</material-button>
            </div>
          </div>
        </div>
      </div>
    </div>


</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import Datepicker from 'vuejs3-datepicker';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {watch} from "vue";

export default {
  data() {
      return {
        dobValue: "", // This is the data property to bind to the input of dob
        confirm_password:"",
        first_name:"",
        middle_name:"",
        last_name:"",
        password:"",
        phone_number:"",
        dob:"",
        email_address:"",
        business_occupation:"",
        selectedNationality:'',
        signupError:"",
        passwordNotMatch:false,
        isEmailBlank:false,
        isFirstNameBlank:false,
        isLastNameBlank:false,
        isOccupationBlank:false,
        isDobBlank:false,
        isEmailAddress:false,
        isFormSubmitted:false,
        isSmokerInvalid: false,
        yesSmokeSelected: false, // Track the selected "Yes" switch
        noSmokeSelected: false,  // Track the selected "No" switch
        showLoader:false
    }
  },
  computed: {
    isEmailInvalid() {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      return !emailRegex.test(this.email_address);
    }
  },
  name: "PaymentAccordion",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialSwitch,
    Datepicker
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    update: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },

  },
  mounted() {
    watch(
        () => this.selectedNationality,
        (newVal) => {
          if (newVal === 'outside-kenya') {
            // Show a toast message when the user selects "Outside Kenya"
            toast.error("Sorry,we're only issuing policy within  " +
                "Kenya for the time being. But watch this space,we could be coming to a golf" +
                "course near you soon!");
          }
        }
    );
  },

  methods: {
    expressCheckout() {
      this.$router.push({ name: 'Login' });
    },
    signupGoogle(){
    },
    isSafaricomNumber(phoneNumber) {
      const safaricomPattern = /(\+?254|0|^){1}[-. ]?[7]{1}([0-2]{1}[0-9]{1}|[9]{1}[0-2]{1})[0-9]{6}$/;
      return safaricomPattern.test(phoneNumber);
    },
    isFieldInvalid(fieldName) {
      if (this.isFormSubmitted) {
        switch (fieldName) {
          case 'email_address':
            return this.isEmailInvalid;

          case 'selectedNationality':
            // Check if the selected nationality is empty or invalid
            return !this.selectedNationality || this.selectedNationality.trim() === '';

          default:
            // For other fields, check if they are empty
            return (typeof this[fieldName] === 'string' && this[fieldName].trim() === '');
        }
      }
      return false;
    },
    submitSignupForm: async function () {
      this.showLoader = true;
      this.signupError = '';
      this.isEmailBlank = false;
      this.isFormSubmitted = true;


      if (!this.isSafaricomNumber(this.phone_number)) {
        this.showLoader = false;
        this.signupError = 'Please enter a valid Safaricom number.';
        // Display an error toast message
        toast.error('Please enter a valid Safaricom number.');
        return;
      }

      if (this.password !== this.confirm_password) {
        this.passwordNotMatch = true;
        this.showLoader = false;

        setTimeout(() => {
          this.passwordNotMatch = false;
        }, 5000);

        return;
      }

      // Validate and display all fields with red styling
      const hasInvalidFields =
          this.isFieldInvalid('first_name') ||
          this.isFieldInvalid('last_name') ||
          this.isFieldInvalid('email_address') ||
          this.isFieldInvalid('phone_number') ||
          this.isFieldInvalid('business_occupation') ||
          this.isFieldInvalid('dob') ||
          this.isFieldInvalid('password') ||
          this.isFieldInvalid('confirm_password');


      // Check and apply the 'is-invalid' class to the specific div
      const datepickerDiv = document.querySelector('.vuejs3-datepicker__value');
      if (datepickerDiv && this.isFieldInvalid('dob')) {
        datepickerDiv.classList.add('is-invalid');
        this.showLoader = false;
      }

      if (hasInvalidFields) {
        // Display a general error message or handle it as needed
        this.signupError = 'Please fill in all the form fields correctly';

        toast.error(this.signupError);
        this.showLoader = false;

        // Clear the error message after 5 seconds
        setTimeout(() => {
          this.signupError = '';
        }, 5000);

        return;
      }

      if (!hasInvalidFields) {
        try {
          const API_URL = process.env.VUE_APP_API_BASE_URL

          const response = await axios.post(API_URL + '/register-package-user', {
            dobValue: this.dob,
            confirm_password: this.confirm_password,
            name: this.first_name,
            middle_name:this.middle_name,
            phone_number:this.phone_number,
            last_name: this.last_name,
            password: this.password,
            smoker: this.yesSmokeSelected,
            dob: this.dob,
            email: this.email_address,
            business_occupation: this.business_occupation,
            selectedNationality: this.selectedNationality,
          });

          // Handle the response here
          // console.log('API response:', response.data);
          if (response.status === 200) {
            toast.success("Successfully submitted details!");
            this.$auth.setToken(response.data.access_token);
            this.$auth.setUser(response.data.user);
            this.showLoader = false;

            // Delay the redirection by 5 seconds
            setTimeout(() => {
              this.$router.push({name: "Checkout"});
            }, 5000);

          } else {
            this.showLoader = false;

            // Handle the validation errors here
            const errors = response.data.errors;

            for (const key in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, key)) {
                toast.error(errors[key][0]);
              }
            }
            // Handle other error cases
          }

          // Optionally, reset the form fields or redirect the user upon success
        } catch (error) {

          const errors = error.response.data;

          this.showLoader = false;

          if (errors && 'errors' in errors) {
            // Access the error messages
            const errorMessages = errors.errors;

            // Iterate through the error messages
            for (const key in errorMessages) {
              if ('hasOwnProperty' in errorMessages && key in errorMessages) {
                toast.error(errorMessages[key][0]);
              }
            }
          } else {
            // Handle other error cases
            toast.error("An error occurred. Please try again.");
          }
        }
      }


    },
    validateSmoker(option) {
      // Reset the validation status
      this.isSmokerInvalid = false;

      if (option === 'yes') {
        this.yesSmokeSelected = true;
        this.noSmokeSelected = false;
      } else if (option === 'no') {
        this.yesSmokeSelected = false;
        this.noSmokeSelected = true;
      }

      if (!this.yesSmokeSelected && !this.noSmokeSelected) {
        this.isSmokerInvalid = true;
      }
    }
  },


}
</script>

