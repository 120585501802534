<template>
  <div class="container-fluid mt-4">
    <div class="row" style="justify-content: center">
      <div class="col-md-6">
        <div class="col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-6 z-index-2">
              <div class="text-center">
              <div  class=" shadow-success momo-banner  py-3 pe-1"
                  style="background: #8e8a8a">
                <i class="fas fa-user text-md ms-3 text-white cursor-pointer"></i>

                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Welcome, {{ username }}
                </h4>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3">
                <div class="text-center">
                  <h6 class="mb-0">Your Package Details:</h6>
                 <br>
                    <div class="d-flex flex-column row">

                      <h6 class="mb-1 text-dark total-text text-sm">
                        <i class="fas fa-medal text-md ms-3 cursor-pointer"></i>
                        &nbsp;&nbsp;Golfer’s insurance silver package</h6><br>
                     Total: KES 6,500
                    </div>
                   <material-button
                      class="my-4 mb-2 crown-scope-button"
                      @click.prevent="completePurchase()"
                  > <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>
                     {{  payment_message }}</material-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import axios from "axios";
import {toast} from "vue3-toastify";

export default {
  name: "Billing",
  data() {
    return {
      user: null,
      username:null,
      phone_number:null,
      showLoader: false,
      payment_message:"Complete Purchase"
    };
  },
  components: {
    MaterialButton,
  },
  created() {
    // Fetch the user data from local storage when the component is created
    this.user = this.$auth.getUser() ?? null;
    this.username = this.user?.name ?? null;
    this.phone_number = this.user?.phone_number ??null;
  },

  methods: {
    completePurchase() {
      this.showLoader = true;
        // const API_URL = process.env.VUE_APP_API_BASE_URL
       axios.post('https://gateway.crownscope.net/crownscope-api/api/v2/initiate-payment', {
          phone_number: this.phone_number,
          amount :6500,
        })
    .then(response => {
        // Assuming response.data contains the JSON response
        const ResponseCode  = response.data.ResponseCode;
      const { ResponseDescription } = response.data.ResponseDescription;

      this.payment_message ="Processing Payment"
      // Handle the response here
      if (ResponseCode == 0) {

        this.showLoader = false;
        this.payment_message ="Payment Processed Successfully!"

        toast.success("Thank you for your purchase .Kindly check your phone to complete payment!");

        // Delay the redirection by 5 seconds
        setTimeout(() => {
          this.$router.push({name: "Confirmation"});
        }, 5000);

        const CheckoutRequestID = response.data.CheckoutRequestID; // Replace this with your actual CheckoutRequestID
        localStorage.setItem('CheckoutRequestID', CheckoutRequestID);


      } else if(ResponseCode == 1032){

        this.showLoader = false;
        this.payment_message ="Retry Payment"

        toast.error("An error occurred please try again");

      }else {
        this.showLoader  = false;
        toast.error(ResponseDescription);

        // Handle other error cases
        this.payment_message ="Complete Purchase";
      }

      })
          .catch(error => {
            console.error('Error fetching ResponseCode:', error);
            // Handle errors, if any
          });

    }
  }
};
</script>
