<template>
  <div class="container-fluid mt-4">

    <div class="row" style="justify-content: center">
      <div class="col-md-6">
        <div class="col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom confirmation-card">
            <div class="card-body">
              <form role="form" class="text-start mt-3">
                <div class="text-center">
<!--                  <h6 class="mb-0">Your Package Details:</h6>-->
                  <br>
                  <material-button
                      class="my-4 mb-2 crown-scope-button"
                  >
                   Hello, {{ username }} Please wait as your payment is being processed.</material-button
                  >
                  <div class="d-flex flex-column row confirmation-div">

                    <h6 class="mb-1 text-dark total-text text-sm">
                      </h6><br>
                    <div v-if="showConfirmLoader">
                    <img
                        src="../assets/img/illustrations/confirmation.gif"
                        class="confirmation-gif"
                        alt="Confirmation gif"
                    />
                    </div>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import axios from "axios";
import {toast} from "vue3-toastify";

export default {
  name: "Confirmation",
  data() {
    return {
      user: null,
      username:null,
      phone_number:null,
      showConfirmLoader:true,
      checkoutRequestId:''
    };
  },
  components: {
    MaterialButton,
  },
  created() {
    // Fetch the user data from local storage when the component is created
    this.user = this.$auth.getUser() ?? null;
    this.username = this.user?.name ?? null;
    this.phone_number = this.user?.phone_number ??null;
  },

  methods: {
    checkPaymentStatus() {
      // Retrieve CheckoutRequestID from localStorage
      const checkoutRequestID = localStorage.getItem('CheckoutRequestID');
      if (!checkoutRequestID) {
        return;
      }

      // Axios request to check payment status
      axios.get('https://gateway.crownscope.net/crownscope-api/v2/check-payment-status', {
        params: {
          checkoutRequestID: checkoutRequestID
        }
      })
          .then(response => {
            // Handle the response here
            console.log('Payment Status Response:', response.data);
            // Further processing based on the response
            if(response.status === 'Confirmed'){
              this.showConfirmLoader = false;
              toast.success("Your Payment has been received successfully,and you should receive your policy documents including a schedule of benefits and a certificate within next 24 hours");

            }else if(response.status){

              this.showConfirmLoader =false;
              toast.error(response.description);

            }else{
              this.showConfirmLoader =false;
              toast.error(response.message);
            }
          })
          .catch(error => {
            // Handle errors, if any
            console.error('Error fetching payment status:', error);
          });
    }

  }
};
</script>
