<template>
  <sidenav
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
    v-if="showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="showNavbar"
    />
    <router-view />
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import { mapMutations, mapState } from "vuex";
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL


export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
  },
  mounted(){
    this.init()
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),

    init(to, from, next) {
      axios.get(API_URL +'/init').then((response) => {
        console.log(response)
        this.is_loaded = true;
        if (response.data.authenticated) {
          this.$store.dispatch('auth/isLoggedIn', true); // mutation name is 'isLoggedIn'

            // Redirect to the intended route if the user is authenticated
            next(this.intendedRoute || { name: 'checkout' });


        } else {
          if(this.$auth.hasToken() && this.$auth.hasUser()) {

            if (this.intendedRoute && typeof this.intendedRoute === 'object') {
              // Navigate to the intended route
              this.$router.push(this.intendedRoute);
            } else {
              // Navigate to the default 'Signup' route
              console.log(this.intendedRoute)
              console.log(to.fullPath)

              localStorage.setItem('intendedRoute', to.fullPath);

              this.$router.push({ name: 'GatewaySignup' });
            }
          }else{
            localStorage.setItem('intendedRoute', to.fullPath);

            this.$router.push({ name: 'GatewaySignup' });
          }
        }
      });
    },
    beforeRouteEnter(to, from, next) {
      // Store the intended route in the instance property
      to.meta.intendedRoute = from;
      next();
    },
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  }
};
</script>
