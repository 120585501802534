<template>
  <div class="card h-50 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="mb-0">Your Package Details</h6>
        </div>

      </div>
    </div>
    <div class="card-body pt-4 p-3">

      <ul class="list-group">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex align-items-center">
            <material-button
              color="success"
              variant="outline"
              size="sm"
              class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-arrow-down" aria-hidden="true"></i>
            </material-button>
            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">Golfer’s insurance silver package</h6>
            </div>
          </div>
          <div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
             KES 6,500
          </div>
        </li>
      </ul>

      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Total :  KES 6500
      </h6>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "transaction-card",
  components: {
    MaterialButton,
  },
};
</script>
