<template>
  <div class="container-fluid">

    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">

        <div class="row mt-4" id="checkout-pay" style="justify-content: center">
          <div class="col-lg-5 col-md-8 ">
            <div class="crown-scope-checkout-image" style="justify-content: center;text-align: center;padding:10px">
               <img src="../assets/img/illustrations/crown-scope.png" alt="">
            </div>
            <payment-accordion
                title="CHECKOUT"
                subtitle="Last Campaign Performance"
                update="campaign sent 2 days ago"
            >
            </payment-accordion>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import PaymentAccordion from "./components/PaymentAccordion.vue";
export default {
  name: "GatewaySignup",
  data() {
    return {

    };
  },
  components: {
   PaymentAccordion
  },
};
</script>
