import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
// plugins
import Auth from './plugins/auth';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Auth)
appInstance.use(MaterialDashboard);

appInstance.use(store);


router.beforeEach((to, from, next) => {
    // Access $auth using inject
    const $auth = appInstance.config.globalProperties.$auth;

    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
        // Check if the user is authenticated
        if ($auth.hasToken()) {
            // Proceed to the route
            next();
        } else {
            next('/sign-up');
        }
    } else {
        // If the route doesn't require authentication, proceed to the route
        next();
    }
});
appInstance.mount("#app");
