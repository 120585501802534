const AuthPlugin = {
    install: (app) => {
        app.config.globalProperties.$auth = {
            token_name: `crown_scope_auth_token`,
            token_user: 'crown_scope_user',
            getToken() {
                return localStorage.getItem(this.token_name);
            },
            getUser() {
                if (this.isTokenExpired()) {
                    this.removeToken(); // Remove the expired token
                    this.removeUser(); // Remove the user data
                    return null;
                }
                const userJSON = localStorage.getItem(this.token_user);
                if (userJSON) {
                    return JSON.parse(userJSON);
                } else {
                    // Handle the case where user data doesn't exist
                    return null;
                }
                },

            hasToken() {
                if (this.getToken() === null) {
                    return false;
                }
                return this.getToken().length !== 0;
            },
            hasUser() {
                if (this.getUser() === null) {
                    return false;
                }
                return this.getUser().length !== 0;
            },
            isTokenExpired() {
                const token = this.getToken();
                if (!token) {
                    return false;
                }

                // Parse the token or extract the expiration date from your token structure
                const tokenData = JSON.parse(atob(token.split('.')[1]));
                const tokenExpiration = tokenData.exp * 1000; // Convert to milliseconds

                const currentTimestamp = new Date().getTime();

                // Check if the token has expired
                return currentTimestamp > tokenExpiration;
            },

            setToken(token) {
                localStorage.setItem(this.token_name, token);
            },
            setUser(user) {
                localStorage.setItem(this.token_user, JSON.stringify(user));
            },
            removeToken() {
                localStorage.removeItem(this.token_name);
            },
            removeUser(){
                localStorage.removeItem(this.token_user);
            }
        };
    },
};

export default AuthPlugin;
