<template>
  <div class="container-fluid">

    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">

        <div class="row" id="checkout-pay" style="justify-content: center">
          <div class="col-lg-5 col-md-8 ">
            <div class="crown-scope-checkout-image" style="justify-content: center;text-align: center;padding:10px">
              <img src="../../assets/img/illustrations/crown-scope.png" alt="">
            </div>
            <div class="card z-index-2">
              <div class="card-body">
                <hr class="dark horizontal" />
                <form role="form" class="text-start mt-3 unregistered-form">
                  <div class="mb-3">
                    <material-input
                        id="email"
                        type="text"
                        label="Email address/Phone number"
                        name="email_address"
                        v-model:value="email_address"
                        :error="isFieldInvalid('email_address')"
                    />
                  </div>

                  <div class="mb-3">
                    <material-input
                        id="password"
                        type="password"
                        label="Password"
                        name="password"
                        v-model:value="password"
                        :error="isFieldInvalid('password')"
                    />
                  </div>
                  <div class="text-center">
                    <material-button
                        class="my-4 mb-2 checkout-login crown-scope-button"
                        variant="gradient"
                        color="success"
                        @click.prevent="submitSignupForm()"
                        fullWidth>
                      <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>Login
                    </material-button>
                  </div>
                </form>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                        :to="{ name: 'GatewaySignup' }"
                        class="text-success text-gradient font-weight-bold"
                    >Sign Up</router-link
                    >
                  </p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from '@/components/MaterialButton.vue';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
// import {watch} from "vue";

export default {
  data() {
    return {
      password:"",
      email_address:"",
      signupError:"",
      isEmailAddress:false,
      showLoader:false,
      isFormSubmitted:false
    }
  },
  computed: {
    isEmailInvalid() {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      return !emailRegex.test(this.email_address);
    }
  },
  name: "PaymentAccordion",
  components: {
    MaterialInput,
    MaterialButton
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    update: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },

  },
  mounted() {

  },

  methods: {

    isFieldInvalid(fieldName) {
      if (this.isFormSubmitted) {
        switch (fieldName) {
          case 'email_address':
            return this.isEmailInvalid;

          default:
            // For other fields, check if they are empty
            return (typeof this[fieldName] === 'string' && this[fieldName].trim() === '');
        }
      }
      return false;
    },
    submitSignupForm: async function () {
      this.showLoader = true;
      this.signupError = '';
      this.isEmailBlank = false;
      this.isFormSubmitted = true;

      // Validate and display all fields with red styling
      const hasInvalidFields =
          this.isFieldInvalid('email') ||
          this.isFieldInvalid('password') ;



      if (hasInvalidFields) {
        // Display a general error message or handle it as needed
        this.signupError = 'Please fill in all the form fields correctly';

        toast.error(this.signupError);
        this.showLoader = false;

        // Clear the error message after 5 seconds
        setTimeout(() => {
          this.signupError = '';
        }, 5000);

        return;
      }


      if (!hasInvalidFields) {

          const API_URL = process.env.VUE_APP_API_BASE_URL

          const response = await axios.post(API_URL + '/login-user', {
            email_or_phone: this.email_address,
            password: this.password,
          });



          if (response.status === 200 && !response.data.errors) {
            toast.success("Login was successful");
            this.$auth.setToken(response.data.access_token);
            this.$auth.setUser(response.data.user);
            this.showLoader = false;

            // Delay the redirection by 5 seconds
            setTimeout(() => {
              this.$router.push({name: "Checkout"});
            }, 5000);

          } else {
            // Handle different HTTP status codes
            if (response.data.errors ) {
              // Unauthorized: Invalid credentials
              this.showLoader = false;
              toast.error("Invalid credentials");
            } else {
              // Other error scenarios
              this.showLoader = false;
              toast.error("An error occurred. Please try again.");
            }

          }

      }


    },
    validateSmoker(option) {
      // Reset the validation status
      this.isSmokerInvalid = false;

      if (option === 'yes') {
        this.yesSmokeSelected = true;
        this.noSmokeSelected = false;
      } else if (option === 'no') {
        this.yesSmokeSelected = false;
        this.noSmokeSelected = true;
      }

      if (!this.yesSmokeSelected && !this.noSmokeSelected) {
        this.isSmokerInvalid = true;
      }
    }
  },


}
</script>

